/* eslint-disable no-throw-literal */
import axios from 'axios';

class BaseService {
  api;

  endpoint;

  constructor(endpoint = String) {
    this.endpoint = endpoint;
    this.api = axios.create({ baseURL: 'https://apiagendamento.econ.com.br/api/' });
    //this.api = axios.create({ baseURL: 'http://localhost:8000/api/' });
  }

  async get() {
    try {
      const response = await this.api.get(this.endpoint);

      return this.handleResponse(response);
    } catch ({ response }) {
      return this.handleResponse(response);
    }
  }

  async post(dados) {
    try {
      const response = await this.api.post(this.endpoint,
        {
          id: dados.id,
          data: dados.data,
          hora: dados.hora,
          cpf: dados.cpf,
          nome: dados.nome,
          telefone: dados.telefone,
          idProcedimento: dados.idProcedimento,
        });

      return this.handleResponse(response);
    } catch ({ response }) {
      return this.handleResponse(response);
    }
  }

  handleResponse(response) {
    if (!response.statusText === 'OK') {
      if (response.status === 401) {
        this.logout();
        document.location.reload(true);
      }

      const error = (response.data && response.data.message) || response.statusText;
      return error;
    }

    if (response.status === 401) {
      this.logout();
      document.location.reload(true);
    }

    return {
      response,
      data: response.data,
      status: response.status,
    };
  }
}

export default BaseService;
